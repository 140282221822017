/* globals google */
'use strict';
/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}


var markers;
var map;
/**
 * Uses google maps api to render a map
 */
function maps() {
    markers = [];
    // Init U.S. Map in the center of the viewport
    var latlng = new google.maps.LatLng(37.09024, -95.712891);
    var mapOptions = {
        scrollwheel: true,
        zoom: 4,
        center: latlng
    };

    map = new google.maps.Map($('.map-canvas')[0], mapOptions);
    var mapdiv = $('.location-dropdown-menus').attr('data-locations');

    mapdiv = JSON.parse(mapdiv);


    var bounds = new google.maps.LatLngBounds();
    var icon = {
        url: $('.google-map-dot-marker').attr('src'),
        scaledSize: new google.maps.Size(18, 18)
    };

    var icon1 = {
        url: $('.google-map-marker').attr('src'),
        anchor: new google.maps.Point(13, 30),
        scaledSize: new google.maps.Size(40, 40)
    };
    var marker;
    var i;

    Object.keys(mapdiv).forEach(function (key) {
        var item = mapdiv[key];
        var storeLocation = new google.maps.LatLng(item.latitude, item.longitude);
        var itemname = item.name;
        marker = new google.maps.Marker({
            position: storeLocation,
            map: map,
            title: itemname,
            icon: icon
        });

        // eslint-disable-next-line no-shadow
        google.maps.event.addListener(marker, 'click', (function (marker, i) {
            return function () {
                for (var j = 0; j < markers.length; j++) {
                    markers[j].setIcon(icon.url);
                }
                marker.setIcon(icon1.url);

                $('.results-card').scrollTop('0');
                var storeID = $(this).attr('title').replace(/[.,\s]/g, '');
                $('.form-check').removeClass('active');
                $('#' + storeID).closest('.form-check').addClass('active');

                $('.results-card').animate({
                    scrollTop: $('#' + storeID).offset().top - 350
                }, 100);
            };
        }(marker, i)));
        markers.push(marker);
        // Create a minimum bound based on a set of storeLocations
        bounds.extend(marker.position);
    });

    // Fit the all the store marks in the center of a minimum bounds when any store has been found.
    if (mapdiv && mapdiv.length !== 0) {
        map.fitBounds(bounds);
    }
}

function heightAdjustResultBlock() {
    var isMobileLocator = $('.store-locator-mobile').is(':visible');
    var resultblockHeight;
    var $locationHeader = $('.location-results-header-block:visible');
    if ($('header').hasClass('header-fixed')) {
        resultblockHeight = $(window).height() - $locationHeader.height() - 200;
    } else {
        resultblockHeight = $(window).height() - $locationHeader.height() - (isMobileLocator ? 180 : 292);
    }

    $('.results-card').css({
        height: resultblockHeight
    });
}


/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */
function updateStoresResults(data) {
    var $resultsDiv;
    var hasResults = data.stores.length > 0;
    var locationTitle = $('.location-title');

    locationTitle.html(data.locationsText + ' ' + '<span>' + $('.store-locator-form:visible').find('#store-postal-code').val() +'</span>');
    
    if (!hasResults) {
        $('.store-locator-no-results').show();
        $('.location-inner-form-block').show();
        $('.location-results-block').removeClass('active');
    } else {
        $('.store-locator-no-results').hide();
        $('.location-inner-form-block').hide();
        $('.changelocation-form-block').find('.location-inner-form-block').show();
        $('.location-results-block').addClass('active');
        heightAdjustResultBlock();
        $resultsDiv = $('.results');
    }

    $resultsDiv.empty()
        .data('has-results', hasResults)
        .data('radius', data.radius)
        .data('search-key', data.searchKey);

    $('.location-dropdown-menus').attr('data-locations', data.locations);

    if ($('.location-dropdown-menus').data('has-google-api')) {
        maps();
    } else {
        $('.store-locator-no-apiKey').show();
    }

    if (data.storesResultsHtml) {
        $resultsDiv.append(data.storesResultsHtml);
    }
    var sessionStore = $('.session-store').val() ? JSON.parse($('.session-store').val()) : null;
    if (sessionStore) {
        $resultsDiv = $('.results:visible').find('#' + sessionStore.ID.replace(/ /g, "-")).find('.form-check').addClass('active');
    }
}

/**
 * function makes call to SFCC backend and gets the store details.
 * @param {string} url to invoke
 * @param {JSON} payload calculated
 * @param {HTML} $mainDiv div to be updated with the searh results
 * @param {string} searchTerm search keyword from the input field
 */
function resolveStore(url, payload, $mainDiv, searchTerm) {
    $.ajax({
        url: url,
        type: $mainDiv.data('method'),
        data: payload,
        dataType: 'json',
        success: function (data) {
            $.spinner().stop();
            if (data.stores.length > 0) {
                updateStoresResults(data);
                $('.location-title span').text(searchTerm);
                window.storeList = data.stores;
            } else {
                var errorMessage = $mainDiv.find('[name="postalCode"]').data('nostoreresult');
                $mainDiv.find('[name="postalCode"]').addClass('is-invalid');
                $mainDiv.find('[name="postalCode"]').siblings('.invalid-feedback').text(errorMessage);
            }
            $('.select-store').prop('disabled', true);
        }
    });
}

/**
 * Search for stores with new zip code
 * @param {HTMLElement} element - the target html element
 * @returns {boolean} false to prevent default event
 */
function search(element) {
    window.storeList = [];
    var payload = {};
    var $mainDiv = element.filter(function(el) {return $(this).is(':visible')}).closest('.store-locator');
    var url = $mainDiv.data('action');
    var searchTerm = $mainDiv.find('[name="postalCode"]').val();
    if (!searchTerm) {
        $('.store-locator-form').find('#store-postal-code').addClass('is-invalid');
        return false;
    }
    $('.store-locator-form').find('#store-postal-code').removeClass('is-invalid');
    $('.location-inner-form-block').find('#store-postal-code').val(searchTerm);
    $('body').addClass('hidden');

    $.spinner().start();
    var isValidPostalCode = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(searchTerm.toUpperCase());
    if (isValidPostalCode) {
        payload.postalCode = !searchTerm.includes(' ') ? searchTerm.substring(0, 3) + ' ' + searchTerm.substring(3) : searchTerm;
        searchTerm = payload.postalCode.toUpperCase();
        payload.postalCode = payload.postalCode.toUpperCase();
        resolveStore(url, payload, $mainDiv, searchTerm);
    } else {
        payload.city = searchTerm;
        payload.alternateGeoCode = {};
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: searchTerm, componentRestrictions: { country: 'CA' } }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var location = results[0].geometry.location;
                payload.lat = location.lat();
                payload.long = location.lng();
                resolveStore(url, payload, $mainDiv, searchTerm);
            } else {
                console.log('Geocode was not successful for the following reason: ' + status);
                resolveStore(url, payload, $mainDiv, searchTerm);
            }
        });
    }

    return false;
}

module.exports = {
    init: function () {
        $('.view-map').on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('cancelmap')) {
                $('.view-map').removeClass('cancelmap');
                $('#map-block').removeClass('active');
                $('body').removeClass('body-fixed');
                $('.secondlevel-menu-block').removeClass('height290');
                $('.results-card').removeClass('mobile-location-card-body');
                $('.navbar-header-block').removeClass('full-screen');
            } else {
                $(this).addClass('cancelmap');
                $('#map-block').addClass('active');
                $('body').addClass('body-fixed');
                maps();

                var num = $('.store-select-block.current').attr('data-index');
                var id = parseFloat(num).toFixed(0);
                if (num) {
                    map.setCenter(markers[id].getPosition());
                    map.setZoom(5);
                    google.maps.event.trigger(markers[id], 'click');
                }

                if ($(window).width() < 991) {
                    $('#map-block').css({
                        width: '100%',
                        height: 350
                    });
                    $('.secondlevel-menu-block').addClass('height290');
                    $('.results-card').addClass('mobile-location-card-body');
                    $('.navbar-header-block').addClass('full-screen');
                } else {
                    $('#map-block').css({
                        width: $(window).width() - 400,
                        height: $(window).height() - 135
                    });
                }
            }
        });

        // Used only on rent storelocator
        $('body').on('click', '.rent-results-block .card-body', function (e) {
            if (!$(e.target).attr('href')) {
                $('.form-check').removeClass('active');
                $(this).find('.form-check').addClass('active');

                var num = $(this).find('.store-select-block').attr('data-index');
                var id = parseFloat(num).toFixed(0);

                map.setCenter(markers[id].getPosition());
                map.setZoom(5);
                google.maps.event.trigger(markers[id], 'click');
            }
        });

        $('body').on('click', '.sales-detail .store-select-block', function () {
            $('.form-check').removeClass('active');
            $('.store-select-block').removeClass('current');
            $(this).addClass('current');
            $(this).closest('.form-check').addClass('active');

            var num = $(this).attr('data-index');
            var id = parseFloat(num).toFixed(0);

            if($('#map-block').is(':visible')) {
                map.setCenter(markers[id].getPosition());
                map.setZoom(5);
                google.maps.event.trigger(markers[id], 'click');
            }

            // Update the selected store to the session
            var $storeDetails = $(this).closest('.store-details');
            var storeID = $storeDetails.data('store-id') || null;
            var url = $storeDetails.data('actionurl');
            var timeout = 300;
            if (storeID) {
                var urlParams = {
                    storeID: storeID
                };
                url = appendToUrl(url, urlParams);
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        if(data.store && data.storeID) {
                            $('.find-location span').text(data.store);
                            $('.find-location .store-locator-mobile-location').text(data.store);

                            // Update session store
                            $('.session-store').val(JSON.stringify(data.sessionStore));
                            $('.session-store').attr('data-storeid', data.storeID);

                            // Close dropdown after location select
                            if (!$('.rent-dropdown-menus').length) {
                                setTimeout(() => {
                                    // Animate the height of the .location-form-block element to 0
                                    $('.location-form-block').animate({ height: 0 }, 300, function() {
                                        $('.find-location').removeClass('current');
                                        $('.location-dropdown-menus').removeClass('active');
                                        $('body').removeClass('hidden');
                                        $('.location-form-block').removeAttr('style');
                                    });
                                }, timeout);
                            }
                        }
                    }
                });
            }
        });
        $('.find-location, .all-location-block').on('click', function () {
            if ($('.location-dropdown-menus').data('has-google-api')) {
                maps();
            }

            var tag = $(this).attr('data-attr');
            if (tag === 'buy') {
                $(this).addClass('current');
                $('.location-dropdown-menus').addClass('active');
                $('#store-postal-code').focus();
                if ($('.location-inner-form-block:visible').length === 0 && !$('#services-clp').length) {
                    $('body').addClass('hidden');
                    heightAdjustResultBlock();
                }
            } else if ($(this).hasClass('current')) {
                $(this).removeClass('current');
                $('.rent-dropdown-menus').removeClass('active');
                $('body').removeClass('hidden');
                $('.form-check').removeClass('active');
                $('#map-block').removeClass('active');
            } else {
                $(this).addClass('current');
                $('.rent-dropdown-menus').addClass('active');
                var resultblockHeight;
                if ($('header').hasClass('header-fixed')) {
                    resultblockHeight = $(window).height() - $('.rent-inner-form-block').height() - 120;
                } else {
                    resultblockHeight = $(window).height() - $('.rent-inner-form-block').height() - 208;
                }

                $('.results-card').css({
                    height: resultblockHeight
                });
                $('body').addClass('hidden');
                $('#map-block').addClass('active');

                maps();
                if ($(window).width() < 991) {
                    $('#map-block').css({
                        width: 315,
                        height: 350
                    });
                    $('.secondlevel-menu-block').addClass('height290');
                    $('.rental-block').addClass('height0');
                    $('.mobile-rent-address-block').addClass('d-none');
                    $('.rent-address-block').addClass('active');
                } else {
                    $('#map-block').css({
                        width: $(window).width() - 400,
                        height: $(window).height() - 135
                    });
                }
            }
        });
        $('.close-location-block').on('click', function () {
            $('.find-location').removeClass('current');
            $('.location-dropdown-menus').removeClass('active');
            $('.view-map').removeClass('cancelmap');
            $('#map-block').removeClass('active');
            $('body').removeClass('hidden');

            if (window.refreshURL !== 'undefined' && window.refreshURL !== '' && window.refreshURL != null) {
                window.location.href = window.refreshURL;
            }
        });

        $('.select-location-text').on('click', function () {
            $('.find-location').addClass('current');
            $('.location-dropdown-menus').addClass('active');

            // Service page storelocator handling
            if ($('.location-inner-form-block:visible').length === 0 && $('#services-clp').length) {
                $('.navbar-toggle-block').trigger('click');
                $('.store-locator-mobile-level-1').trigger('click');
            }
        });

        $('.store-locator-form').on('submit', function (e) {
            e.preventDefault();
            search($('.store-locator .btn-storelocator-search'));
        });

        if ($('.location-detail-tabs .nav-tabs li').length === 1) {
            $('.location-detail-tabs .activetab-text').addClass('no-event');
        }
    },
    search: function () {
        $('.store-locator .btn-storelocator-search').click(function (e) {
            e.preventDefault();
            search($(this));
        });
    },
    detectLocation: function () {
        // clicking on detect location.
        $('.detect-location').on('click', function () {
            $.spinner().start();
            if (!navigator.geolocation) {
                $.spinner().stop();
                return;
            }

            navigator.geolocation.getCurrentPosition(function (position) {
                var $detectLocationButton = $('.detect-location');
                var geocoder = new google.maps.Geocoder();
                var radius = $('.results').data('radius');
                var urlParams = {
                    radius: radius,
                    lat: position.coords.latitude,
                    long: position.coords.longitude
                };
                var url = $detectLocationButton.data('action');
                url = appendToUrl(url, urlParams);

                $.when(
                    $.Deferred(function(deferred) {
                        geocoder.geocode({'location': {lat: urlParams.lat, lng: urlParams.long}}, function(results, status) {
                            if (status === 'OK' && results[0]) {
                                for (var i = 0; i < results[0].address_components.length; i++) {
                                    var addressType = results[0].address_components[i].types[0];
                                    if (addressType === 'postal_code') {
                                        var postalCode = results[0].address_components[i].long_name;
                                        $('.store-locator-form').find('#store-postal-code').val(postalCode);
                                        deferred.resolve();
                                        return;
                                    }
                                }
                            }
                            deferred.reject();
                        });
                    }),

                    $.ajax({
                        url: url,
                        type: 'get',
                        dataType: 'json'
                    }).done(function(response) {
                        // Handle successful response here + handle error loading data
                        $.spinner().stop();
                    }).fail(function(jqXHR, textStatus, errorThrown) {
                        // Handle error here
                        $.spinner().stop();
                        console.error('Error:', textStatus, errorThrown);
                    })
                )
                .then(function(_, response) {
                    $.spinner().stop();
                    updateStoresResults(response[0]);
                    $('.select-store').prop('disabled', true);
                });
            });
        });
    },
    loadFooterStores: function () {
        var stores = $('.stores-data').val() ? JSON.parse($('.stores-data').val()) : null;
        var sessionStore = $('.session-store').val() ? JSON.parse($('.session-store').val()) : null;
        var defaultFacebookURL = $('.footer-stores').children(':first').data('facebook-url');
        if (stores) {
            var options = '';
            if (stores) {
                stores.sort(function (a, b) {
                    var nameA = a.name.toLowerCase();
                    var nameB = b.name.toLowerCase();
                     // sort string ascending
                    if (nameA < nameB) { return -1; }
                    if (nameA > nameB) { return 1; }
                    return 0; // default return value (no sorting)
                });
                stores.forEach(function (store) {
                    var storeUrl = store.facebookURL && store.facebookURL !== 'undefined' ? store.facebookURL : defaultFacebookURL;
                    if (sessionStore && sessionStore.ID === store.ID) {
                        options = options + '<option selected value="' + store.ID + '" data-facebook-url="' + storeUrl + '">' + store.name + '</option>';
                        $('.connect-but-block a').attr('href', storeUrl);
                    } else {
                        options = options + '<option value="' + store.ID + '" data-facebook-url="' + storeUrl + '">' + store.name + '</option>';
                    }
                });
                $('select.footer-stores').append(options);
            }
        }

        $('.footer-stores').on('change', function () {
            if (facebookURL) {
                $('.connect-but-block a').attr('href', facebookURL);
            }
        });
    }
};
