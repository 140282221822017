'use strict';

function initImageCheck() {
  // Get all product hero carousel img elements on the PDP page
  const pdpImages = $('.product-hero-carousel img');
  // Get all img elements with class name floorplan image and product tile image on the PLP page
  const plpImages = $('.image-floorplan, .title-image');
  const productImages = [...pdpImages, ...plpImages];

  productImages.forEach((productImage) => {
    // Check if the image width and height = 0 - this is damaged image
    if (productImage.naturalWidth === 0 && productImage.naturalHeight === 0 ) {

      const parentClassName = $(productImage).closest('.pdp-hi-res, .floor-plans').attr('class');
      let noImageFileName;
      let params;

      if (productImage.src.indexOf('?sw=') !== -1) {
        // Extract the parameters from the URL
        params = productImage.src.split('?')[1];

        if (parentClassName && parentClassName.indexOf('pdp-hi-res') !== -1) {
          // Replace the broken image source with the appropriate default image for the PDP hi-res image
          noImageFileName = 'noimagehi-res-fraserway.png';
        } else if ($(productImage).hasClass('title-image')) {
          // Replace the broken image source with the appropriate default image for the PLP image
          noImageFileName = 'noimagemedium-hi-res-fraserway.png';
        }
      } else {
        if (parentClassName && parentClassName.indexOf('floor-plans') !== -1) {
          // Replace the broken image source with the appropriate default image for the floor plans image
          noImageFileName = 'noimage-floor-plans-fraserway.png';
        } else if ($(productImage).hasClass('image-floorplan')) {
          // Replace the broken image source with the appropriate default image for the PLP floor plan image
          noImageFileName = 'noimagemedium-hi-res-fraserway.png';
        }
      }

      if (noImageFileName) {
        // Replace the broken image source with the appropriate default image
        const imagePath = productImage.src.split('?')[0];
        const basePath = imagePath.substring(0, imagePath.lastIndexOf('/images/'));
        const cleanedPath = basePath + '/images/' + noImageFileName;
        productImage.src = cleanedPath + (params ? '?' + params : '');
      }
    }
  });
}

module.exports = initImageCheck;

