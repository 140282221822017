'use strict';
var scrollAnimate = require('base/components/scrollAnimate');
var emailId;

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function getModalHtmlElement() {
    if ($('#emailSubscription').length !== 0) {
        $('#emailSubscription').remove();
    }
    var htmlString = '<!-- Modal -->' +
    '<div class="modal fade emailSubscription" id="emailSubscription" role="dialog">' +
    '<div class="modal-dialog">' +
    '<div class="modal-content">' +
    '    <button type="button" class="close pull-right" data-dismiss="modal">' +
    '        <span aria-hidden="true">&times;</span>' +
    '    </button>' +
    '<div class="modal-body">' +
    '<h3 class="email-subscription-heading">' + $('.email-subscription-heading').val() + '</h3>' +
    '<div class="email-subscription-subHeading">' + $('.email-subscription-subheading').val() + '</div>' +
    '<div class="custom-form"></div>' +
    '</div>' +
    '</div>' +
    '</div>' +
    '</div>';
    $('body').append(htmlString);
}

function getLocation() {
    var sessionStore = $('.session-store').data('storeid');
    var locationMapping = $('.location-mapping').val();
    locationMapping = locationMapping && JSON.parse(locationMapping);
    var location = locationMapping[sessionStore];
    return location;
}

function fillModalElement() {
    $('#emailSubscription .custom-form').empty();
    $('#emailSubscription').modal('show').find('.custom-form').load($('.email-subscription-form').val(), function () {
        setTimeout(function () {
            var location = getLocation();
            $('select[name*="Account.Location__pc"]').find('option[value=' + location + ']').attr('selected', 'selected');
            if ($('head title').length === 0) {
                $('head').append('<title></title>');
            }
            $('head title').text($('.custom-page-title').val());
        }, 2500);
    });
}

function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
        getModalHtmlElement();
        fillModalElement();
        var timer = setInterval(function () {
            $('#ffPage84 input[placeholder="Email"]').val(emailId);

            if ($('#ffPage84 input[placeholder="Email"]').val() == '') {

            } else if ($('#ffPage84 input[placeholder="Email"]').val() == undefined) {

            } else {
               clearInterval(timer);
            }
        }, 1000);
    } else {
        status = 'alert-danger';
        if ($('.email-signup-message').length === 0) {
            $('body').append(
               '<div class="email-signup-message"></div>'
            );
        }
        $('.email-signup-message')
            .append('<div class="email-signup-alert text-center ' + status + '">' + data.msg + '</div>');

        setTimeout(function () {
            $('.email-signup-message').remove();
            button.removeAttr('disabled');
        }, 3000);
    }
}

module.exports = function () {
    $('.back-to-top').click(function () {
        scrollAnimate();
    });

    $('body').on('hide.bs.modal', '#emailSubscription', function () {
        location.reload();
    });

    if ($('.email-subscription.form').length > 0) {
        $('.email-subscription.form').find('.email-subscription-body').load($('.email-subscription-form').val(), function () {
            setTimeout(function () {
                var location = getLocation();
                $('select[name*="Account.Location__pc"]').find('option[value="' + location + '"]').attr('selected', 'selected');
                $('input[name*="Account.PersonEmail"]').val($('.subscription-email').val());
                if ($('head title').length === 0) {
                    $('head').append('<title></title>');
                }
                $('head title').text($('.custom-page-title').val());
            }, 2500);
        });
    }

    $('.subscribe-email').on('click', function (e) {
        e.preventDefault();
        var url = $(this).data('href');
        var emailSubcriptionPageUrl = $(this).data('emailsubscribe-show');
        var button = $(this);
        emailId = $('input[name=hpEmailSignUp]').val();
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId
            },
            success: function (data) {
                if (data.error) {
                    displayMessage(data, button);
                } else {
                    window.location.href = emailSubcriptionPageUrl;
                }
            },
            error: function (err) {
                displayMessage(err, button);
            }
        });
    });

    $('.current-year').html(new Date().getFullYear());

    $('body').on('click', '.footer-container .footermenu-block .footer-mob-title', function () {
        if ($(this).closest('.footermenu-block').hasClass('active')) {
            $('.footermenu-block').removeClass('active');
        } else {
            $('.footermenu-block').removeClass('active');
            $(this).closest('.footermenu-block').addClass('active');
        }
    });
};
