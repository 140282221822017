'use strict';

var debounce = require('lodash/debounce');
var endpoint = $('.suggestions-wrapper').data('url');
var enableSearchSuggestion = $('.enable-search-suggestion').val();
var minChars = 1;
var UP_KEY = 38;
var DOWN_KEY = 40;
var DIRECTION_DOWN = 1;
var DIRECTION_UP = -1;

var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $('header').outerHeight();
var allgalleryImage = [];

function handleAnchorRef(getAnchorParam) {
    const querystring = new URLSearchParams(location.search);
    const anchorRef = querystring.get('anchor');

    // If "anchor" query string param exist
    if (anchorRef && anchorRef !== 'undefined') {
        // Get the anchored DOM element
        const $anchorEl = $(`[anchor="${anchorRef}"]`);

        // Prevents the FormStack scroll the page to the top
        window.history.scrollRestoration = 'manual';
        window.FF_OnBeforeRender = () => {
            return false;
        };

        if (getAnchorParam) {
            return anchorRef;
        }

        if ($anchorEl.length) {
            $(window).off('scroll');
            $('html, body').animate({scrollTop: $anchorEl.offset().top - 50}, 'slow', function () {
                if (!$anchorEl.find('.accrdion-heading').hasClass('active')) {
                    $anchorEl.find('.accrdion-heading').click();
                }

                $(window).on('scroll');
            });
        }        
    }
}

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).closest('form').find('.suggestions-wrapper');
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest('.search-mobile').length;
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    $('body').removeClass('modal-open');
    $('header').siblings().attr('aria-hidden', 'false');
    $('.suggestions').removeClass('modal');
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
    if (isMobileSearch(scope)) {
        $('body').addClass('modal-open');
        $('header').siblings().attr('aria-hidden', 'true');
        getSuggestionsWrapper(scope).find('.suggestions').addClass('modal');
    }
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
    $('input.search-field').val('');
    clearModals();
    $('.search-mobile .suggestions').unbind('scroll');
    $('.suggestions-wrapper').empty();
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    var mobileSearchIcon = '.search-mobile button.';
    var iconSearch = 'fa-search';
    var iconSearchClose = 'fa-close';

    if (action === 'close') {
        $(mobileSearchIcon + iconSearch).removeClass(iconSearch).addClass(iconSearchClose).attr('type', 'button');
    } else {
        $(mobileSearchIcon + iconSearchClose).removeClass(iconSearchClose).addClass(iconSearch).attr('type', 'submit');
    }
}

/**
 * Determines whether the "More Content Below" icon should be displayed
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(scope) {
    if (($(scope).scrollTop() + $(scope).innerHeight()) >= $(scope)[0].scrollHeight) {
        $('.more-below').fadeOut();
    } else {
        $('.more-below').fadeIn();
    }
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    var outerHeight;
    var $scope;
    var $suggestions;
    var top;

    if (isMobileSearch(scope)) {
        $scope = $(scope);
        top = $scope.offset().top;
        outerHeight = $scope.outerHeight();
        $suggestions = getSuggestionsWrapper(scope).find('.suggestions');
        $suggestions.css('top', top + outerHeight);

        handleMoreContentBelowIcon(scope);

        // Unfortunately, we have to bind this dynamically, as the live scroll event was not
        // properly detecting dynamic suggestions element's scroll event
        $suggestions.scroll(function () {
            handleMoreContentBelowIcon(this);
        });
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    if (enableSearchSuggestion === 'true') {
        var $suggestionsWrapper = getSuggestionsWrapper(this).empty();

        $.spinner().stop();
        if (typeof (response) !== 'object') {
            $suggestionsWrapper.append(response).show();
            //$(this).siblings('.reset-button').addClass('d-none');
            $('.search-button').addClass('search-icon');
            positionSuggestions(this);

            if (isMobileSearch(this)) {
                toggleSuggestionsIcon('close');
                applyModals(this);
            }

            // Trigger screen reader by setting aria-describedby with the new suggestion message.
            var suggestionsList = $('.suggestions .item');
            if ($(suggestionsList).length) {
                $('input.search-field').attr('aria-describedby', 'search-result-count');
            } else {
                $('input.search-field').removeAttr('aria-describedby');
            }
        } else {
            $suggestionsWrapper.hide();
        }
        var value = $('input.search-field').val();
        $(".search-keys").html('" ' + value + ' "');
    }
}

 $('.search-field').on('keyup', function () {
    var value = $(this).val();
    $(".search-keys").html('" ' + value + ' "');
});


/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
        $(scope).siblings('.reset-button').removeClass('d-none');
        if (enableSearchSuggestion === 'true') {
            $.spinner().start();
        }
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: processResponse,
            error: function () {
                $.spinner().stop();
            }
        });
    } else {
        toggleSuggestionsIcon('search');
        $(scope).siblings('.reset-button').addClass('d-none');
        $('.search-button').removeClass('search-icon');
        clearModals();
        getSuggestionsWrapper(scope).empty();
        var value = $('input.search-field').val();
    	$(".search-keys").html('" ' + value + ' "');
    }
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(direction) {
    // get all li elements in the suggestions list
    var suggestionsList = $('.suggestions .item');
    if (suggestionsList.filter('.selected').length === 0) {
        suggestionsList.first().addClass('selected');
        $('input.search-field').each(function () {
            $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
        });
    } else {
        suggestionsList.each(function (index) {
            var idx = index + direction;
            if ($(this).hasClass('selected')) {
                $(this).removeClass('selected');
                $(this).removeAttr('aria-selected');
                if (suggestionsList.eq(idx).length !== 0) {
                    suggestionsList.eq(idx).addClass('selected');
                    suggestionsList.eq(idx).attr('aria-selected', true);
                    $(this).removeProp('aria-selected');
                    $('input.search-field').each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.eq(idx)[0].id);
                    });
                } else {
                    suggestionsList.first().addClass('selected');
                    suggestionsList.first().attr('aria-selected', true);
                    $('input.search-field').each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
                    });
                }
                return false;
            }
            return true;
        });
    }
}

function feedbackSliderCount() {
    var $sliderCount = $('.slider-count-feedback');
    var $slickElement = $('.quote-overlay');

    $slickElement.on('init reInit afterChange', function (event, slick, currentSlide) {
        var i = (currentSlide || 0) + 1;
        $sliderCount.text(i + '/' + slick.slideCount);
    });
}

function departmentMobileSlider() {
    $('.mobileOnly').slick({
        infinite: false,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: true
    });
}

module.exports = function () {
    handleAnchorRef();

    $('form[name="simpleSearch"]').submit(function (e) {
        var suggestionsList = $('.suggestions .item');

        if (!$(this).find('input.search-field').val().length) {
            e.preventDefault();
            return;
        }

        if (suggestionsList.filter('.selected').length !== 0) {
            e.preventDefault();
            suggestionsList.filter('.selected').find('a')[0].click();
        }
    });

    if ($('.category-menus-block').is(':visible')) {
        $('#maincontent').addClass('top-margin');
    }

    if ($('.our-services').length > 0) {
        $('.our-services .our-services-right').find('.nav-item:first-child .service-tab').addClass('active');
        $('.our-services .our-services-right').find('.service-tab-content:first-child').addClass('active');

        $('body').on('click', '.service-tab', function() {
            $('.viewMore').addClass('d-none');
            $('.our-services .our-services-right').find('.service-tab').removeClass('active');
            $(this).addClass('active');
            var id = $(this).data('id');
            $('.service-tab-content').removeClass('active');
            $('#'+id).addClass('active');

            $('#'+id).find('ul li').each(function (index) {
                if (index > 28) {
                    $(this).addClass('active');
                    $('.viewMore').removeClass('d-none');
                }
            });
        });

        $('.service-tab-content:first-child ul li').each(function (index) {
            if (index > 28) {
                $(this).addClass('active');
                $('.viewMore').removeClass('d-none');
            }
        });

        $('.no-tab-content ul li').each(function (index) {
            if (index > 28) {
                $(this).addClass('active');
                $('.viewMore').removeClass('d-none');
            }
        });
    }

    if ($('.tabbed-list').length > 0) {
        $('body').on('click', '.tabbed-list .custom-tabs-dropdown-menu .nav-link', function () {
            $('.tabbed-list .custom-tabs-dropdown-menu').find('.nav-link').removeClass('active');
            $(this).addClass('active');
            var id = $(this).data('id');
            $('.tabbed-list-content').removeClass('active');
            $('#'+id).addClass('active');
        });
    }

    if ($('.dynamic-accrdion-component').length > 0) {
        $('body').on('click', '.dynamic-accrdion-component .accrdion-heading', function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).closest('.card').find('.accordion-description').slideUp('slow');
            } else {
                $(this).addClass('active');
                $(this).closest('.card').find('.accordion-description').slideDown('slow');
            }
        });
    }

    $('input.search-field').each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);
        $(this).on('keyup focus', function (e) {
            // Capture Down/Up Arrow Key Events
            switch (e.which) {
                case DOWN_KEY:
                    handleArrow(DIRECTION_DOWN);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                case UP_KEY:
                    handleArrow(DIRECTION_UP);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                default:
                    debounceSuggestions(this, e);
            }
        });
    });

    $('body').on('click', function (e) {
        if (!$('.suggestions').has(e.target).length && !$(e.target).hasClass('search-field')) {
            $('.suggestions').hide();
        }
    });

    $('body').on('click touchend', '.search-mobile button.fa-close', function (e) {
        e.preventDefault();
        $('.suggestions').hide();
        toggleSuggestionsIcon('search');
        tearDownSuggestions();
    });

    $('.site-search .reset-button').on('click', function () {
        $(this).removeClass('d-block');
        // $('.search-button').removeClass('search-icon');
        $('.search-field').focus();
        $("#searchForm").val("");
    });
    
    $('.level-1-drop-down-nav-link').on('click', function() {
        $(this).parent('.level-1-div-block').next('.category-menus-block').addClass('active');
    });
    $('.level-2-drop-down-nav-link').on('click', function(e) {
        if($(window).width() > 991) {
            return;
        }
        e.preventDefault();
        $(this).next('.megamenu-block').addClass('active');
        $(this).parents('.category-menus-block').addClass('inactive');
        $('.level-3-breadcrumbs').empty().append($(this).html());
    });

    $('.static-nav-links').on('click', function(e) {
        e.preventDefault(); // Remove this line after page "About us" will be completed

        if ($('.navbar-header-block').hasClass('active')) {
            $(this).next('.header-right-block-second-menus').addClass('active');
        }
    });

    $('.accordion-menu-bock	 h4').on('click', function() {
        if($(this).hasClass('active')){
            $(this).removeClass('active');
            $(this).next('.leve-3-menus').removeClass('open');
        }else{
            $(this).addClass('active');
            $(this).next('.leve-3-menus').addClass('open');
        }
    });
	
    $('.navbar-toggle-block').on('click', function () {
        $('.navbar-header-block').addClass('active');
        $('body').addClass('fixedBody');
        $('.overlay-block').addClass('active');
    });
    $('.close-navbar-toggle-block').on('click', function () {
        $('.navbar-header-block').removeClass('active');
        $('body').removeClass('fixedBody');
        $('.category-menus-block').removeClass('active');
        $('.megamenu-block').removeClass('active');
        $('.header-right-block-second-menus').removeClass('active'); 
	    $('.overlay-block').removeClass('active');	    
	    $(".secondlevel-menu-block").removeClass("height290");
        $(".rental-block").removeClass("height0");
        $("#map-block").removeClass("active");
        $('.view-map').removeClass('cancelmap');
        $(".all-location-block").removeClass("current");
        $("body").removeClass("hidden");
        $(".mobile-rent-address-block").removeClass("d-none");
        $(".rent-address-block").removeClass("active");
        $("body").removeClass("body-fixed");	
        $('.navbar-header-block').removeClass('full-screen');    
    });

    $('.browse-rv-btn').on('click', function (e) {
        e.preventDefault();
        $('.navbar-header-block').addClass('active');
        $('body').addClass('fixedBody');
        $('#buy').parent('.level-1-div-block').next('.category-menus-block').addClass('active');
        $('#shop-rvs').next('.megamenu-block').addClass('active');
		$('#shop-rvs').parents('.category-menus-block').addClass('inactive');
    });
	 $('.back-to-level-1-menu').on('click', function(e) {
		$(this).closest('.category-menus-block').removeClass('active');
		$(".secondlevel-menu-block").removeClass("height290");
        $(".rental-block").removeClass("height0");
        $("#map-block").removeClass("active");
        $('.view-map').removeClass('cancelmap');
        $(".all-location-block").removeClass("current");
        $("body").removeClass("hidden");
        $(".mobile-rent-address-block").removeClass("d-none");
        $(".rent-address-block").removeClass("active");
        $('.navbar-header-block').removeClass('full-screen');
	});
	$('.back-to-level-2-menu').on('click', function(e) {
		$(this).closest('.megamenu-block').removeClass('active');
		$(this).parents('.category-menus-block').removeClass('inactive');
	});

	$('.back-to-main-menu').on('click', function(e) {
		$(this).closest('.header-right-block-second-menus').removeClass('active');
	});
	$('body').on('click', '.search-icon-block', function() {
        if ($(this).hasClass('active')) {
            $('.close-search-block').trigger('click');
            $('body').removeClass('hidden-1');
        } else {
            $('.search-bar-block').addClass('active');
		    $('.category-menus-block').addClass('hide-category-menu');
            $('body').addClass('hidden-1');
       		$(this).addClass('active');
       		$('#map-block').removeClass('active');
       		$('body').removeClass('fixedBody');
       		$('.view-map').removeClass('cancelmap');
            if ($('.search-field').val().length > 1) {
                var currentVal = $('.search-field').val();
                $('.search-field').val('').focus().val(currentVal);
            } else {
                $('.search-field').focus();
            }
        }
	});
	$('.close-search-block').on('click', function() {
		$('.search-bar-block').removeClass('active');
		$('.category-menus-block').removeClass('hide-category-menu');
		$('.search-icon-block').removeClass('active');
		$('body').removeClass('hidden-1');
	});

	$('.changelocation-link').on('click', function() {
		$('.changelocation-form-block').find('.location-inner-form-block').toggle();
		$('.store-locator-form .form-control').focus();
		var resultblockHeight = $(window).height() - $('.location-results-header-block').height() - 292;
        $('.results-card').css({
            'height': resultblockHeight
        });
        if ($(window).width() < 991) {
        	$('#map-block').removeClass('active');
        	$(".secondlevel-menu-block").removeClass("height290");
        	$('.view-map').removeClass('cancelmap');
        	$(".results-card").removeClass("mobile-location-card-body");
        	$('.navbar-header-block').removeClass('full-screen');
        }
	});

	// Hide Header on on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('header').outerHeight();

    $(window).scroll(function (event) {
        if (handleAnchorRef(true)) {
            window.FF_OnBeforeRender = () => {
                return false;
            };
        }

        didScroll = true;
    });

    setInterval(function () {
        if (didScroll) {
            var st = $(this).scrollTop();

            // Make sure they scroll more than delta
            if (Math.abs(lastScrollTop - st) <= delta)
                return;

            // If they scrolled down and are past the navbar, add class .nav-up.
            // This is necessary so you never see what is "behind" the navbar.
             if (st > lastScrollTop && st > navbarHeight) {
			    // Scroll Down
			    //$('header').removeClass('nav-down').addClass('nav-up');
			    $('.onepage-menu-block').removeClass('onepage-menu-down');
			    if ($(window).width() > 1023) {
			        if($('.selected-nav').find('.category-menus-block').length){
			        	$('.main-menus').addClass('floating-menus');
			        	$('header').removeClass('remove-header-fixed').addClass('header-fixed');
			        }else{
			        	$('header').removeClass('remove-header-fixed-1').addClass('header-fixed-1');
			        }
                    // stiky filter menu fix because of colapsable header on scroll
                    $('.side-bar-fliters').length && $('.side-bar-fliters').css('top', '4rem');
			    }else{
                    $('.side-bar-fliters').length && $('.side-bar-fliters').css('top', '');
			    	$('header').removeClass('remove-header-fixed-1').removeClass('header-fixed-1');
				    $('header').removeClass('remove-header-fixed').removeClass('header-fixed');
			    	$('header').removeClass('remove-smalldevice-header-fixed').addClass('smalldevice-header-fixed');
			    }
			  } else {
			    // Scroll Up
			    if (st + $(window).height() < $(document).height()) {
			      //$('header').removeClass('nav-up').addClass('nav-down');
					$('.onepage-menu-block').addClass('onepage-menu-down');
			       if ($(window).width() > 1023) {
				        if($('.selected-nav').find('.category-menus-block').length){
				        	$('.main-menus').removeClass('floating-menus');
				        	$('header').removeClass('header-fixed').addClass('remove-header-fixed');
				        }else{
				        	$('header').removeClass('header-fixed-1').addClass('remove-header-fixed-1');
				        }
                        // stiky filter menu fix because of colapsable header on scroll
                        $('.side-bar-fliters').length && $('.side-bar-fliters').css('top', '10rem');
				    }else{
                        $('.side-bar-fliters').length && $('.side-bar-fliters').css('top', '');
				    	$('header').removeClass('remove-header-fixed-1').removeClass('header-fixed-1');
				    	$('header').removeClass('remove-header-fixed').removeClass('header-fixed');
				    	$('header').removeClass('smalldevice-header-fixed').addClass('remove-smalldevice-header-fixed');
				    }
			    }
			  }

            lastScrollTop = st;
            didScroll = false;
        }
    }, 100);

    $('body').on('click', '.activetab-text', function () {
        if ($(this).hasClass('current')) {
            $('.activetab-text').removeClass('current');
            $('.nav-tabs').removeClass('active');
        } else {
            $(this).addClass('current');
            $(this).next('.nav-tabs').addClass('active');
        }
    });

    $('body').click(function (e) {
        if (!$(e.target).closest('.activetab-text').length) {
            $('.activetab-text').removeClass('current');
            $('.nav-tabs').removeClass('active');
        }
    });

    $('body').on('click', '.nav-tabs .nav-link', function () {
        $('.activetab-text').empty().append($(this).attr('aria-controls'));
        $('.nav-tabs').removeClass('active');
        $('.activetab-text').removeClass('current');
    });

    $('.parts-tabs .custom-tabs .nav-link').on('click', function() {
        var id = $(this).attr('href');
        $('.parts-tabs-right .tabs-image').removeClass('show');
        $('.parts-tabs-right').find(id).addClass('show');
    });

    $('body').on('click', '.tab-activated-link', function () {
        if ($(this).hasClass('current')) {
            $('.tab-activated-link').removeClass('current');
            $('.tab-links').removeClass('active');
        } else {
            $(this).addClass('current');
            $(this).next('.tab-links').addClass('active');
        }
    });

    $('body').click(function (e) {
        if (!$(e.target).closest('.tab-activated-link').length) {
            $('.tab-activated-link').removeClass('current');
            $('.tab-links').removeClass('active');
        }
    });

    $('.mobile-content-data .tab-links li').on('click', function() {
        var id = $(this).attr('id');
        $('.mobile-content-data .content .tab-content').removeClass('active');
        $('.mobile-content-data .content').find('#'+id).addClass('active');
        $('.tab-activated-link').empty().append($(this).text());
        $('.tab-activated-link').removeClass('current');
        $('.tab-links').removeClass('active');
    });

    $(window).resize(function () {
        if ($(this).innerWidth() <= 1024) {
            if (!$('.mobileOnly').hasClass('slick-initialized')) {
                departmentMobileSlider();
            }
        } else {
            if ($('.mobileOnly').hasClass('slick-initialized')) {
                $('.mobileOnly').slick('unslick');
            }
        }
    });

    if ($(window).innerWidth() <= 1024) {
        departmentMobileSlider();
    }

    $('.featured-product').slick({
		  infinite: false,
		  speed: 300,
		  slidesToShow: $('.featured-product').hasClass('comingsoon-products') ? 4 : 3,
		  slidesToScroll: 1,
		  arrows: true,
		  dots: true,
          dotsClass: 'slick-dots featured-dots',
          prevArrow: '<button type="button" class="slick-prev featured-slick-arrow featured-prev">Previous</button>',
          nextArrow: '<button type="button" class="slick-next featured-slick-arrow featured-next">Next</button>',
		  responsive: [
		    {
		        breakpoint: 1024,
		        settings: {
		            arrows: false,
                    centerPadding: '40px',
                    slidesToShow: 2,
		            slidesToScroll: 1
		        }
		    },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerPadding: '40px',
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
              }
		  ]
    });

    feedbackSliderCount();

    $('.quote-overlay').slick({
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: $('.text-feedback-arrows .prev-arrow'),
        nextArrow: $('.text-feedback-arrows .next-arrow'),
        responsive: [
		    {
		      breakpoint: 1024,
		      settings: {
		        slidesToShow: 1,
                slidesToScroll: 1
		      }
		    }
		]
    });

    $('.department-carousel-3').slick({
        infinite: false,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        responsive: [
		    {
		      breakpoint: 1024,
		      settings: {
		        arrows: false,
                dots: true,
		        slidesToShow: 1,
                slidesToScroll: 1
		      }
		    }
		]
    });

    $('.content-table-data').slick({
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
    });

    $('.department-carousel-4').slick({
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        responsive: [
		    {
		      breakpoint: 1024,
		      settings: {
		        arrows: false,
                dots: true,
		        slidesToShow: 1,
                slidesToScroll: 1
		      }
		    }
		]
    });

    $('.fourcardsup .experience-tile').slick({
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        responsive: [
		    {
		      breakpoint: 1024,
		      settings: {
		        arrows: false,
                dots: true,
		        slidesToShow: 1,
                slidesToScroll: 1
		      }
		    }
		]
    });

    $('.generic-gallary-images .experience-tile').slick({
        infinite: false,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        responsive: [
		    {
		      breakpoint: 1024,
		      settings: {
		        arrows: false,
                dots: true,
		        slidesToShow: 1,
                slidesToScroll: 1
		      }
		    }
		]
    });
    
    

    $('.parts-and-service-carousel, .browseRV-carousel').slick({
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
            },
		    {
		      breakpoint: 1024,
		      settings: {
		        arrows: true,
		        slidesToShow: 2,
                slidesToScroll: 1
		      }
		    }
		]
    });

    $('.RV-special-carousel').slick({
        infinite: false,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        responsive: [
        	{
		      breakpoint: 1024,
		      settings: {
		        arrows: false,
		        slidesToShow: 2,
                slidesToScroll: 1
		      }
		    },
		    {
		      breakpoint: 767,
		      settings: {
		        arrows: false,
		        slidesToShow: 1,
                slidesToScroll: 1
		      }
		    }
		]
    });

    $('body').on('click', '.part-services-tab-carousel .nav-link', function () {

        $('.part-services-tab-carousel').addClass('overlay');
        $.spinner().start();
        setTimeout(() => {
            $('.parts-and-service-carousel').slick('unslick');
            $('.parts-and-service-carousel').slick({
                infinite: false,
                speed: 300,
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: true,
                dots: true,
                responsive: [
                    {
                    breakpoint: 1024,
                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                    }
                ]
            });
            $('.part-services-tab-carousel').removeClass('overlay');
            $.spinner().stop();
        }, 500);
    });

    $('.gallary-image').each(function () {
        var image = $(this).attr('data-image');
        if (image !== undefined) {
            allgalleryImage.push(image);
        }
    });
    var divdata;
    if(allgalleryImage.length > 0){
    	for(var i=0; i < allgalleryImage.length; i++){
    		divdata = "<div class='image-container'><div class='expand-image' style='background-image:url("+ allgalleryImage[i] +")'></div></div>"
    		$('.expand-image-container').append(divdata);
    	}
    }
    $('body').on('click', '.expand-generic-gallery-box', function () {

    	if (screen.width < 1024) {
            expandGenericGallery();
            var body = $('.expand-image-container').html();
            var title = $('.generic-gallary-images-heading h2').html();
            $('#expandGenericGalleryModal .modal-header h3').empty().append(title);
            $('#expandGenericGalleryModal .modal-body').empty().html(body);
            $('#expandGenericGalleryModal').modal('show');
            return;
        }else{
	    	$(".generic-gallary-images-1").addClass("active");
	    	$(".generic-gallary-images").addClass("inactive");
	         $('.expand-image-container').slick({
		        infinite: false,
		        speed: 300,
		        slidesToShow: 2,
		        slidesToScroll: 1,
		        arrows: true,
		        dots: true,
		        responsive: [
				    {
				      breakpoint: 1024,
				      settings: {
				        arrows: false,
		                dots: true,
				        slidesToShow: 1,
		                slidesToScroll: 1
				      }
				    }
				]
		    });
		}
    });
    $('body').on('click', '.collapse-generic-gallery-box', function () {
    	$(".generic-gallary-images-1").removeClass("active");
    	$(".generic-gallary-images").removeClass("inactive");
    	$('.generic-gallary-images .experience-tile').slick({
	        infinite: false,
	        speed: 300,
	        slidesToShow: 2,
	        slidesToScroll: 1,
	        arrows: true,
	        dots: true,
	        responsive: [
			    {
			      breakpoint: 1024,
			      settings: {
			        arrows: false,
	                dots: true,
			        slidesToShow: 1,
	                slidesToScroll: 1
			      }
			    }
			]
	    });
    });

    function expandGenericGallery() {
	    if ($('#expandGenericGalleryModal').length !== 0) {
	        $('#expandGenericGalleryModal').remove();
	    }
	    var htmlString = '<!-- Modal -->' +
	    '<div class="modal fade" id="expandGenericGalleryModal" role="dialog">' +
	    '<span class="enter-message sr-only" ></span>' +
	    '<div class="modal-dialog expand-Gallery-dialog container">' +
	    '<!-- Modal content-->' +
	    '<div class="modal-content">' +
	    '<div class="modal-header">' +
	    '    <h3></h3>' +
	    '    <button type="button" class="close pull-right quickmodal-close-button" data-dismiss="modal">' +
	    '    </button>' +
	    '</div>' +
	    '<div class="modal-body"></div>' +
	    '</div>' +
	    '</div>' +
	    '</div>';
	    $('body').append(htmlString);
	}

    $('body').on('click', '.rentalrates-block .custom-tabs .nav-tabs .nav-link', function () {
        var tabId = $(this).data('tab-id');
        $('.tab-content').find('.tab-pane').removeClass('active');
        $('#'+tabId).find('.tab-pane').addClass('active');
    });

    $('body').on('click', '.select-location-text', function () {
        $('.find-location').trigger('click');
    });

    $('body').click(function (e) {
        if (!$(e.target).closest('.activetab-link').length) {
            $('.activetab-link').removeClass('current');
            $('.drop-down-tab-links ul').removeClass('active');
        }

        if (!$(e.target).closest('.append-year-data').length) {
            $('.append-year-data').removeClass('current');
            $('.years-listing-block').removeClass('active');
        }

        if (!$(e.target).closest('.append-season-data').length) {
            $('.append-season-data').removeClass('current');
            $('.seasons-listing-block').removeClass('active');
        }
    });

    $('body').on('click', '.append-year-data', function () {
        $('.append-season-data').removeClass('current');
        $('.seasons-listing-block').removeClass('active');
        if ($(this).hasClass('current')) {
            $('.append-year-data').removeClass('current');
            $('.years-listing-block').removeClass('active');
        } else {
            $(this).addClass('current');
            $(this).next('.years-listing-block').addClass('active');
        }
    });

    var yearId;
    $('body').on('click', '.years-listing-block li', function () {
        $('.append-year-data').empty().append($(this).html());
        $('.append-season-data').empty().append('Season');
        $('.append-year-data').removeClass('current');
        $('.years-listing-block').removeClass('active');
        yearId = $(this).attr('data-tab');
    });

    $('body').on('click', '.append-season-data', function () {
        $('.append-year-data').removeClass('current');
        $('.years-listing-block').removeClass('active');
        if ($(this).hasClass('current')) {
            $('.append-season-data').removeClass('current');
            $('.seasons-listing-block').removeClass('active');
        } else {
            $(this).addClass('current');
            $('#' + yearId).addClass('active');
        }
    });

    $('body').on('click', '.seasons-listing-block .seasons-list', function () {
        $('.append-season-data').empty().append($(this).html());
        $('.append-season-data').removeClass('current');
        $('.seasons-listing-block').removeClass('active');
    });

    $('body').on('click', '.ff-ui-dialog-buttonset button', function () {
        location.reload();
    });

    $('body').on('click', '.social-icon-block-inner-right li', function () {
    		var $temp = $("<input>");
			var $url = $(location).attr('href');
           $("body").append($temp);
		  $temp.val($url).select();
		  document.execCommand("copy");
		  $temp.remove();
		  $("body").append("<div class='urlcopied'><h2>Copy Link & Share</h2></div>");
		  setTimeout(function(){
			  $('.urlcopied').remove();
		  }, 2000);
    });
};
