$(document).ready(function () {
  // Change refinement-filter-block top position on asset-slider init
  $(".asset-slider").on('init', function () {
    if ($('.product-refinement-filter-block').length) {
        $('.product-refinement-filter-block').css('top', $('header').height()-1);
    }
  });

  $(".asset-slider").slick({
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
  });

  $("[data-slick-init]").slick({
    arrows: false,
    dots: true,
    centerMode: true,
    centerPadding: "20%",
  });
});